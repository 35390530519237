import { Component, OnInit } from '@angular/core';
import { DestroyService } from '@shared/services/destroy.service';
import { filter, takeUntil } from 'rxjs';
import { AbstractHeaderComponent } from '../../abstracts/header.abstract';
import { HEADER_OTHER_NAV_LIST } from '../../header.routes';
import { IntercomService } from '@core/services/intercom.service';

@Component({
	selector: 'findqo-header-offcanvas',
	templateUrl: './header-sidenav.component.html',
	styleUrls: ['./header-sidenav.component.scss'],
	providers: [DestroyService],
})
export class HeaderSidenavComponent extends AbstractHeaderComponent implements OnInit {
	constructor(
		private readonly destroyService: DestroyService,
		private intercomService: IntercomService
	) {
		super();
	}

	readonly otherNavList = HEADER_OTHER_NAV_LIST;

	onClose(): void {
		this.offcanvas.dismiss();

		if (this.router.url === '/') {
			this.intercomService.toggelIntercomPopup(false);
		}
	}

	private onBreakpointChange(): void {
		this.bpoService.isDesktop$
			.pipe(
				takeUntil(this.destroyService),
				filter((isDesktop: boolean) => isDesktop)
			)
			// Close the Header sidenav on desktop view
			.subscribe(() => this.offcanvas.dismiss());
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.onBreakpointChange();
	}
}
