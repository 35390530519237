import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../data-access/services/auth.service';
import { APP_ROUTE } from '@core/constants';

@Injectable({
	providedIn: 'root',
})
export class BussinessProfileGuard {
	constructor(
		private readonly auth: AuthService,
		private readonly router: Router
	) {}

	canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const HAS_BUSINESS_ACCOUNT = +this.auth.user()?.hasBusinessProfile;

		if (HAS_BUSINESS_ACCOUNT) {
			return true;
		}

		this.router.navigate([APP_ROUTE.home]);
		return false;
	}
}
